import { Drawer, Stack } from "@mui/material";
import DrawerTitle from "./DrawerTitle.tsx";
import DrawerTabs from "./DrawerTabs";

const Index = ({
  isDrawerOpen,
  onDrawerClose,
}: {
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
}) => {
  return (
    <Drawer
      PaperProps={{
        variant: "outlined",
        elevation: 0,
        sx: {
          width: "65vw",
        },
      }}
      anchor="right"
      open={isDrawerOpen}
      onClose={onDrawerClose}
    >
      <Stack p={3} spacing={3.5} sx={{ height: "100%" }}>
        <DrawerTitle onDrawerClose={onDrawerClose} />
        <DrawerTabs />
      </Stack>
    </Drawer>
  );
};

export default Index;
